import React from 'react';
import { Typography, Box } from '@mui/material';
import Layout from '../layout/Layout';
import colors from '../theme/colors';

const Soon = () => (
    <Layout>
        <Box style={{
            background: colors.darkViolet,
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
        >
            <Typography variant="h3" style={{ fontSize: '48px' }}>
                Próximamente...
            </Typography>
        </Box>
    </Layout>
);

export default Soon;
